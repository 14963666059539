import Vue from 'vue'
import App from '../components/dynamic-form/App.vue'
import { Validator } from 'vee-validate';
import VeeValidate from 'vee-validate';
import VueSweetalert2 from 'vue-sweetalert2';

import 'sweetalert2/dist/sweetalert2.min.css';

Validator.extend('phone', {
    validate: value => value.toString().replaceAll('-', '').replaceAll('(', '').replaceAll(')', '').replaceAll('+', '').replaceAll(' ', '').length >= 10
});

Vue.use(VeeValidate, { inject: false });
Vue.use(VueSweetalert2);

document.addEventListener('DOMContentLoaded', () => {
    const app = new Vue({
        render: h => h(App, {
            props: {
                data: form,
                catalogs: (typeof catalogs === 'undefined' ? [] : catalogs),
                textHelpers: (typeof textHelpers === 'undefined' ? {} : textHelpers),
                locale: locale
            }
        })
    }).$mount('#dynamic-form')
})